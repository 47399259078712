import { EventRegistrationForm } from './components/3-molecules/form/EventRegistrationForm.js'

if (process.env.NODE_ENV === 'development') {
    import('./mocks/browser.js').then(({ worker }) => {
        worker.start({ onUnhandledRequest: 'bypass' })
    })
}

window.addEventListener('load', () => {
    document.body.classList.add('is-loaded')
}, {
    once: true
})

window.addEventListener('DOMContentLoaded', () => {
    const registrationFormElement = document.querySelector('[data-component="eventRegistrationForm"]')
    if (registrationFormElement) {
        // eslint-ignore-next-line no-new
        new EventRegistrationForm(registrationFormElement)
    }
})
