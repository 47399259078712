class ApiResponseError extends Error {

}

class EventRegistrationForm {
    /**
     *
     * @param root {HTMLElement}
     */
    constructor(root) {
        this.root = root

        this.initialize()
    }

    initialize() {
        // Toggle age and dateOfBirth depending on savePersonalData checkbox
        this.savePersonalData = this.root.querySelector('[data-component="savePersonalData"]')
        this.age = this.root.querySelector('[data-component="age"]')
        this.dateOfBirth = this.root.querySelector('[data-component="dateOfBirth"]')
        if (this.savePersonalData && this.age && this.dateOfBirth) {
            this.savePersonalData.addEventListener('input', () => {
                if (this.savePersonalData.querySelector('input').checked) {
                    this.age.style.display = 'none'
                    this.dateOfBirth.style.display = ''
                } else {
                    this.age.style.display = ''
                    this.dateOfBirth.style.display = 'none'
                }
            })
        }

        // Price type and price overview
        this.priceType = this.root.querySelector('[data-component="priceType"]')
        if (this.priceType) {
            this.priceType.addEventListener('input', () => {
                this.updatePriceOverview()
            })
        }

        // Additional participant
        this.additionalParticipant = this.root.querySelector('[data-component="additionalParticipant"]')
        if (this.additionalParticipant) {
            this.additionalParticipant.addEventListener('input', ev => {
                this.personFieldset.innerHTML = ''
                this.personFieldset.style.display = 'none'
                const radio = ev.target
                if (radio.value === 'existing') {
                    this.partnerContainer.style.display = ''
                    if (this.partnerPriceContainer) {
                        this.partnerPriceContainer.style.display = ''
                    }
                } else {
                    this.partnerContainer.style.display = 'none'
                    this.partner.selectedIndex = -1

                    if (radio.value === 'new') {
                        if (this.partnerPriceContainer) {
                            this.partnerPriceContainer.style.display = ''
                        }

                        this.loadPersonForm()
                    } else {
                        if (this.partnerPriceContainer) {
                            this.partnerPriceContainer.style.display = 'none'
                        }
                    }
                }
                this.updatePriceOverview()
            })
        }

        // Partner select
        this.partnerContainer = this.root.querySelector('[data-component="partnerContainer"]')
        this.partner = this.root.querySelector('[data-component="partner"]')
        if (this.partner) {
            this.partner.addEventListener('input', () => {
                this.loadPersonForm().then(() => {
                    this.updatePriceOverview()
                })
            })
        }

        this.partnerPriceContainer = this.root.querySelector('[data-component="partnerPriceContainer"]')
        this.partnerPriceType = this.root.querySelector('[data-component="partnerPriceType"]')
        if (this.partnerPriceType) {
            this.partnerPriceType.addEventListener('input', () => {
                this.updatePriceOverview()
            })
        }

        // Person fieldset
        this.personFieldset = this.root.querySelector('[data-component="personFieldset"]')
        if (this.personFieldset) {
            this.personFormUrl = this.personFieldset.dataset.url

        }

        this.priceOverview = this.root.querySelector('[data-component="priceOverview"]')
        this.priceUrl = this.priceOverview.dataset.url
    }

    updatePriceOverview() {
        fetch(this.priceUrl, {
            method: 'POST',
            credentials: 'include',
            body: new FormData(this.root)
        }).then(response => {
            if (response.status === 200) {
                return response.text().then(html => {
                    this.priceOverview.innerHTML = html
                })
            } else {
                return response.json().then(json => {
                    if (json.message) {
                        throw new ApiResponseError(json.message)
                    } else {
                        throw new Error('Unknown server error')
                    }
                })
            }
        }).catch(e => {
            if (e instanceof ApiResponseError) {
                this.priceOverview.innerHTML = `<p>${e.message}</p>`
            } else {
                this.priceOverview.innerHTML = '<p>Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.</p>'
            }
        })
    }

    loadPersonForm() {
        this.personFieldset.innerHTML = ''
        return fetch(this.personFormUrl, {
            method: 'POST',
            credentials: 'include',
            body: new FormData(this.root)
        }).then(response => {
            if (response.status === 200) {
                return response.text().then(html => {
                    this.personFieldset.innerHTML = html
                    this.personFieldset.style.display = ''
                })
            } else {
                return response.json().then(json => {
                    if (json.message) {
                        throw new ApiResponseError(json.message)
                    } else {
                        throw new Error('Unknown server error')
                    }
                })
            }
        }).catch(e => {
            if (e instanceof ApiResponseError) {
                this.personFieldset.innerHTML = '<p>' + e.message + '</p>'
            } else {
                this.personFieldset.innerHTML = '<p>Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.</p>'
            }
            this.personFieldset.style.display = ''
        })
    }
}

export { EventRegistrationForm }
